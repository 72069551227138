<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Task Due Date</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="quote">
              <tbody>
                  <tr v-for="(v, k, index) in quote" :key="index" v-if="k !== 'id' && k !== 'user_id' && k !== 'status_pretty' && k !== 'options' && k !== 'assigned_to_pretty'">
                      <th v-if="k === 'period'">{{ k|capitalize }} (Days)</th>
                      <th v-else>{{ k|capitalize }}</th>
                      <td v-if="k === 'type'">
                        {{model.type}}
                      </td>
                      <td v-else-if="k === 'period'">
                        <b-form-input
                        id="amount-input"
                        type="number"
                        v-model.number="model.period"
                        step="1"
                         :state="validateState(k)"
                        required
                    ></b-form-input>
                      </td>
                      <td v-else>{{ v }}</td>
                  </tr>
              </tbody>
          </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="pull-left">Back</b-button>
          <b-button @click="Submit" variant="outline-success" class="float-right mr-2">Submit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>
  </div>
</template>

<script>
  import axios from '../../shared/axios/auth'
  import { validationMixin } from 'vuelidate'
  import { required } from "vuelidate/lib/validators";

  export default {
    name: 'TaskConfigEdit',
    mixin: [validationMixin],
    data: () => {
      return {
        quote: null,
        model: {
          period: null,
          type: null,
        },
        options: {}
      }
    },
    validations() {
      return {
        model: {
          period: {
              required
          },
        }
      }
    },
    methods: {
      validateState(name) {

        if (this.$v.model[name]) {
          const {$dirty, $error} = this.$v.model[name];
          return $dirty ? !$error : null;
        }
      },
      goBack() {
        this.$router.go(-1)
      },
      getQuote() {

        let url = '/tasksconfig/' + this.$route.params.id + '/';

        axios.get(url).then(
          response => {
            this.quote = response.data
            this.model = response.data
          }
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        return this.quote
      },
      Submit() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {
          return
        } else {
          // Valid

          let url = '/tasksconfig/' + this.$route.params.id + '/';

          var post_data = {
            'type': this.model.type,
            'period': this.model.period
          }

          axios.put(url, post_data).then(
            response => {
              console.log(response.data)
              this.error = false;
              this.$router.push({ name: 'TasksConfig'})
            }
          ).catch(error => {
              console.log('post error');
              console.log(error.response);

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
          })

        }
      }
    },
    mounted() {
      this.getQuote()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
